<div class="container">

  <app-home-bg></app-home-bg>
  <div class="logo">
    <h1 class="logoTitle">USDRAWING</h1>
  </div>
  <div class="hero">
    <div class="text">
      <h1 class="my-name">שלמה בר</h1>
      <h3>היי, נעים מאוד.<br>
        אז בכמה מילים עליי, אני בן 29, עוסק בתחום חווית משתמש כבר 6 שנים, ליוויתי מאות יזמים<br>
        כפרילנסר וכשכיר ועבדתי עם המון מפתחים מרוצים.<br>
        תוכלו לראות כאן חלק מהפרויקטים שאפיינתי וניהלתי ועלו לאוויר.
      </h3>

      <!-- buttons -->
      <div class="cta-btns">
        <div class="read-more-btn" routerLink="/about-me">עוד קצת עליי</div>
        <div class="contact-me-btn" routerLink="/contact-me">יצירת קשר</div>
      </div>
      <!-- buttons -->

    </div>
    <!-- <a target="_blank"
      href="https://www.google.com/maps/place/Na'an/@31.8843387,34.8495977,15z/data=!3m1!4b1!4m5!3m4!1s0x1502b7d43666d6e3:0xe88526f9b0ad8a22!8m2!3d31.883208!4d34.859237">
      <div class="location">
        <div class="right-white"></div>
        <span class="material-icons">
          location_on
        </span>
        <span class="my-address">קיבוץ נען</span>
      </div>
    </a> -->

  </div>

  <div class="projects">

    <app-projects-section></app-projects-section>
  </div>

  <!-- <div class="reviews">
    <app-reviewes-section></app-reviewes-section>
  </div> -->
</div>