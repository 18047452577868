<div class="projects">

  <span class="projects-header">אתרים</span>

  <div class="apps-holder">
    <div class="app-projects-holder" *ngFor="let projects of projectsArray; let i = index">
      <app-project-card class="item" [projectLogo]="projects?.projectLogo" [projectName]="projects?.projectName"
        [projectAddress]="projects?.projectAddress" [projectContent]="projects?.projectContent" [projectID]="i">
      </app-project-card>
    </div>
  </div>

  <br>
  <br>
  <span class="projects-header">אפליקציות</span>

  <div class="apps-holder">
    <div class="app-projects-holder" *ngFor="let appProject of appsProjectsArray; let i = index">
      <app-app-project-card class="item" [projectLogo]="appProject?.projectLogo" [projectName]="appProject?.projectName"
        [projectAddress]="appProject?.projectAddress" [projectContent]="appProject?.projectContent" [projectID]="i">
      </app-app-project-card>
    </div>
  </div>

</div>