<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>

<div class="container">
    <div class="go-back" routerLink="/home">
        <span class="material-icons">
            arrow_forward
        </span>
    </div>

    <div class="image-holder">
        <img src="assets/projects/about_me.jpg"
            alt="Shlomo Bar :)">
    </div>
    <div class="hero">
        <div class="text" >
            <h1 class="my-name">שלמה בר</h1>
            <span>היי, נעים מאוד.</span>
            <span>אז בכמה מילים עליי - אני בן 29, גר בקיבוץ נען, רוכב על קטנוע ואוהב ליצור ולצייר.</span>
            <span>בתחום חווית המשתמש אני עוסק כבר 6 שנים, במהלך התקופה הזו ליוויתי מאות יזמים,</span>
            <span>גם כפרילנסר וגם כשכיר ועבדתי עם המון מפתחים מרוצים.</span>
            <br>
            <span>כמומחה חווית משתמש יצא לי לאפיין אפליקציות, אתרים, חנויות, מערכות ניהול ודשבורדים</span>
            <span>עם מאות יזמים ולכל הקהלים - ילדים, צעירים ומבוגרים, טכנולוגים ופחות.</span>
            <br>
            <span>הדגש שלי ליזמים ובכלל כאג'נדה לפיתוח: MVP</span>
            <span>מיזם טוב מתחיל בדרך הזו ואפשר לראות את זה על כל הגדולות בשוק שעברו את אותם</span>
            <span>צעדים בקטן כשיצאו, ממש רגע לפני שהפכו למעצמות.</span>
            <br>
            <span>אז בגדול אם יש לך מיזם טוב, מעניין, משנה או מעורר השראה, אשמח לקחת בו חלק,</span>
            <span>לפחות בתכנון החוויה.</span>
        </div>
        <div class="contact-me">
            <span class="title">יצירת קשר</span>
            <div class="contact-options">
                <a href="tel:+972502237058"><span class="material-icons">phone</span></a>
                <a href="mailto:usdrawing@gmail.com" target="_blank"><span class="material-icons">email</span></a>
                <a href="http://wa.me/972502237058" target="_blank"><i class="fab fa-whatsapp"></i></a>
                <a href="https://www.facebook.com/shlomo.bar" target="_blank"><i class="fab fa-facebook"></i></a>
                <a href="https://www.linkedin.com/in/shlomo-bar-a48340100" target="_blank"><i
                        class="fab fa-linkedin"></i></a>
            </div>
        </div>
    </div>
</div>