import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss']
})
export class ProjectPageComponent implements OnInit {

  project: any;

  // Websites projects
  public projectsArray: Array<any> = [
    {
      projectName: 'Get Key‎',
      projectAddress: 'https://getkey.online/home',
      projectLogo: 'assets/projects/GETKEY.png',
      projectTags: 'אפיון, ניהול פיתוח',
      projectPlatforms: 'Website, Dashboard',
      projectContent: 'אתר להשכרת/ רכישת נכסים מפרטיים, עסקיים ויזמים ללא דמי תיווך',
      projectGallery: [
        'https://drive.google.com/file/d/1jlpRijq73uCuzuoEMGleY804-iqHKpsR/view?usp=sharing',
        'https://drive.google.com/file/d/1HZmFOqE8Yj_aOG4h8ajvbBi36kXjDktS/view?usp=sharing'
      ]
    },
    {
      projectName: 'PopToApp‎',
      projectAddress: 'https://poptoapp.com/home',
      projectLogo: 'assets/projects/PopToApp.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח, פיתוח',
      projectPlatforms: 'Website',
      projectContent: 'אתר המאפשר לבצע שליחת הודעות ווטסאפ לאנשים שאינם באנשי הקשר',
      projectGallery: [
        'https://drive.google.com/file/d/1jlpRijq73uCuzuoEMGleY804-iqHKpsR/view?usp=sharing',
        'https://drive.google.com/file/d/1HZmFOqE8Yj_aOG4h8ajvbBi36kXjDktS/view?usp=sharing'
      ]
    },
    {
      projectName: 'DeepDiveDetailing',
      projectAddress: 'https://deepdivedetailing.com/',
      projectLogo: 'assets/projects/deepdivedetailing.png',
      projectTags: 'אפיון, ניהול פיתוח',
      projectPlatforms: 'Website, Dashboard',
      projectContent: 'אתר לתזמון וקביעת שירות נקיון לסירות ויאכטות, האתר פעיל בארצות הברית LV',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    },
    {
      projectName: 'EvenTime',
      projectAddress: 'https://eventime.co.il/',
      projectLogo: 'assets/projects/EvenTime.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח',
      projectPlatforms: 'Website',
      projectContent: 'אתר לתכנון וניהול של אירועים מול הספקים השונים, האתר מאפשר גישה לספקים וגם למחפשים',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    },
    {
      projectName: 'גינת אגוז',
      projectAddress: 'https://ginategoz.co.il/',
      projectLogo: 'assets/projects/GINAT_EGOZ.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח, פיתוח',
      projectPlatforms: 'Website',
      projectContent: 'אתר לאיתור מילים בתורה - ראשי תיבות, גימטריה ופסוקים',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    },
    {
      projectName: 'instaport',
      projectAddress: 'https://instaport.app/projects',
      projectLogo: 'assets/projects/instaport.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח',
      projectPlatforms: 'Website',
      projectContent: 'מערכת להפקת דוחות פרוייקטים המבוססים על פעילות MSP, ממשק חכם ואוטומטי לשיקוף המצב',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    },
    {
      projectName: 'livi',
      projectAddress: 'https://livi.co.il/auth/login',
      projectLogo: 'assets/projects/Livi.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח',
      projectPlatforms: 'Website',
      projectContent: 'מערכת לניהול דיירים בפרוייקטי תמ"א ופינוי בינוי, המערכת מיועדת למינהלת, לבעלי המקצוע ולדיירים',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    }
  ];


  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(paramsId => {
      console.log(paramsId.id)
      const id = paramsId.id;
      this.project = this.projectsArray[id];
    });
  }

}
