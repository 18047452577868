<div class="allPage">

    <div class="go-back" routerLink="/home">
        <span class="material-icons">
            arrow_forward
        </span>
    </div>

    <div class="container">
        <h1 class="title-header">יצירת קשר</h1>
        <h3 class="sentence">ניתן ליצור איתי קשר בטלפון, בווטסאפ או במייל
            ובכל הקישורים המצורפים כאן.
            </h3>

        <div class="contact-options-holder">
            <div class="titles-holder">
                <span class="contat-option-title">טלפון</span>
                <span class="contat-option-title">מייל</span>
            </div>
            <div class="values-holder">
                <span class="contat-option-value">050-2237058</span>
                <span class="contat-option-value">usdrawing@gmail.com</span>
            </div>
        </div>

        <div class="active-links">
            <div class="contact-me">
                <span class="title">אפשר גם בקליק</span>
                <div class="contact-options">
                    <a href="tel:+972502237058"><span class="material-icons">phone</span></a>
                    <a href="mailto:usdrawing@gmail.com" target="_blank"><span class="material-icons">email</span></a>
                    <a href="http://wa.me/972502237058" target="_blank"><i class="fab fa-whatsapp"></i></a>
                    <a href="https://www.facebook.com/shlomo.bar" target="_blank"><i class="fab fa-facebook"></i></a>
                    <a href="https://www.linkedin.com/in/shlomo-bar-a48340100" target="_blank"><i
                            class="fab fa-linkedin"></i></a>
                </div>
            </div>
        </div>

    </div>
</div>