<div class="container">

    <span class="reviewer-image">
        <img src="{{reviewerImg}}" alt="reviewer image">
    </span>

    <div class="texts">
        <span class="reviewer-name">{{reviewerName}}</span>
        <span class="review-content">{{reviewerContent}}</span>
    </div>
</div>



<!-- <span class="reviewer-image">
            <img src="#" alt="reviewer image">
{{reviewerImg}}</span>
    
<div class="texts">
    <span class="reviewer-name">{{reviewerName}}</span>
    <span class="review-content">{{reviewContent}}</span>
</div> -->