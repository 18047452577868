<div class="container">

    <div class="bottom-sheat">

        <div class="close-panel" routerLink="/home">
            <span class="material-icons">
                close
            </span>
        </div>

        <!-- <a (click)="navigateToAppPrevProject()">
            <div class="prev-app">
                <span class="material-icons">
                    arrow_forward_ios
                </span>
            </div>
        </a>
        <a (click)="navigateToAppPrevProject()">
            <div class="next-app">
                <span class="material-icons">
                    arrow_back_ios
                    </span>
            </div>
        </a> -->



        <h1 class="project-name">{{project.projectName}}</h1>

        <div class="top-section">
            <div class="image-holder">
                <img class="project-image cover" [src]="project.projectLogo" alt="porject cover image">
            </div>
        </div>

        <div class="bottom-section">
            <div class="info-row">
                <span>בפרויקט זה לקחתי חלק ב: </span>
                <span>{{project.projectTags}}</span>
            </div>
            <div class="info-row">
                <span>פרויקט זה מורכב מ: </span>
                <span>{{project.projectPlatforms}}</span>
            </div>

            <div class="info-row">
                <span>על הפרויקט בקצרה: </span>
                <span>{{project.projectContent}}</span>
            </div>
            <!-- 
            <div class="gallery">
                <img class="gallery-image" src="{{project.projectGallery[0]}}" alt="fuck you">
                <img class="gallery-image" src="{{project.projectGallery[1]}}" alt="fuck you">
                <img class="gallery-image" src="{{project.projectGallery[2]}}" alt="fuck you">
            </div> -->

        </div>

        <div class="action-btns">
            <a href="{{project.projectAddress}}" target="_blank">
                <div class="filled-btn">
                    <span>הצגה אונליין</span>
                </div>
            </a>
        </div>
    </div>
</div>