import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProjectCardComponent } from './project-card/project-card.component';
import { FooterComponent } from './footer/footer.component';
import { HomeBgComponent } from './home-bg/home-bg.component';
import { ProjectsSectionComponent } from './projects-section/projects-section.component';
import { HomeComponent } from './home/home.component';
import { ProjectPageComponent } from './project-page/project-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { ContactMeComponent } from './contact-me/contact-me.component';
import { ReviewCardComponent } from './review-card/review-card.component';
import { ReviewesSectionComponent } from './reviewes-section/reviewes-section.component';
import { AppProjectCardComponent } from './app-project-card/app-project-card.component';
import { AppProjectPageComponent } from './app-project-page/app-project-page.component';

@NgModule({
  declarations: [
    AppComponent,
    ProjectCardComponent,
    FooterComponent,
    HomeBgComponent,
    ProjectsSectionComponent,
    HomeComponent,
    ProjectPageComponent,
    PageNotFoundComponent,
    AboutMeComponent,
    ContactMeComponent,
    ReviewCardComponent,
    ReviewesSectionComponent,
    AppProjectCardComponent,
    AppProjectPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
