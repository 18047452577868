<div class="reviews">

    <span class="reviews-header">מה אומרים עלי</span>

    <div class="reviews-holder">
        <div class="review-holder" *ngFor="let reviews of reviewsArray">
            <app-review-card class="item" [reviewerImg]="reviews?.reviewerImg" [reviewerName]="reviews?.reviewerName"
                [reviewerContent]="reviews?.reviewerContent">
            </app-review-card>
        </div>
    </div>
</div>