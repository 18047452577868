import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-project-card',
  templateUrl: './app-project-card.component.html',
  styleUrls: ['./app-project-card.component.scss']
})
export class AppProjectCardComponent implements OnInit {

  @Input() projectAddress?: string;
  @Input() projectLogo?: string;
  @Input() projectName?: string;
  @Input() projectContent?: string;
  @Input() projectTags?: string;
  @Input() projectPlatforms?: string;
  @Input() projectID?: number;

  constructor(private router: Router) { }

  ngOnInit(): void { }

  navigateToAppCurrentProject() {
    console.log(this.projectID)
    this.router.navigate([`projects/app/${this.projectID}`])
  }

}
