import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {

  @Input() projectAddress?: string;
  @Input() projectLogo?: string;
  @Input() projectName?: string;
  @Input() projectContent?: string;
  @Input() projectTags?: string;
  @Input() projectPlatforms?: string;
  @Input() projectID?: number;

  constructor( private router: Router) {
    
   }

  ngOnInit(): void {
  }

  navigateToCurrentProject(){
    console.log(this.projectID)
    this.router.navigate([`projects/${this.projectID}`])
  }

}
