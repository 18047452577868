import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-projects-section',
  templateUrl: './projects-section.component.html',
  styleUrls: ['./projects-section.component.scss']
})
export class ProjectsSectionComponent implements OnInit {

  project?: {
    projectName: string,
    projectDesc: string,
    projectAddress: string,
    projectLogo: string,
    projectID?: number
  };

  appProject?:{
    projectName: string,
    projectDesc: string,
    projectAddress: string,
    projectLogo: string,
    projectID?: number
  };

  // profile image:  https://lh3.googleusercontent.com/WDExfTYzIaprpp3vzIWYeYIXDL0WhKQkIF9NhZx1UvAgavU4A7zcTbx3d6X4fP50NYPI6nH0k1w0NtYq7VpEUmtAHCl3YbJVx3imygwlk5Unc2eRYbO8isKkTyXhwWJhqaFk_TXUBrNN6AXBIhhy9tYA-HHXOIAeHSM1it0fX5Wo7N8d_7k7541vwwEPEiWiKNGk4llRSVrAZV3jt9id3Qp4cyarajc6jpZGjkzM_RIgLeBUYCJLcmesISrdws1CZ9JvL9ffi5PA-UffLm9MJO4bxYH-W1hd8-T3I57Od_jGtXxcmVnST0AAM-K2aNkeCAfSMdlAruLOnZT3x5wp5Tk-_7ZA_pNAMrIr3UaqaODmQaCqJetAIzNzHlBR1z08WjNyUwg-ryeEsfBzZI-o6UolTKss2F7cH2q0-xrBfKehurnx-GLWpohuse7mBBCcJqZb0zN3tml_OFqgxAWDBxRrqVi9fsPgYklY1Z7nn3QSXOl5Ml6LWSzZqYlclEhHkITvCT21ZP2AE5lP_W1Qe0kUUmeeSrEotEWeQC96r9PArimDdZPk2HFgAJZD_CLX7sGy0YZak_XTmQ18pgqCuFRW9Sg0Pva9xC7Gjy3QDypilbgskg1IKzar4tIE_lG95T0X8ioOcjHglRrsewvMwRasUb8a1fx4KO0uXmZmUNh7SqEieYJV-c2urs7dVQeo0yiPe7YXEYW4gquCw4JTP_lP=w678-h903-no?authuser=0

  // Websites projects
  public projectsArray: Array<any> = [
    {
      projectName: 'Get Key‎',
      projectAddress: 'https://getkey.online/home',
      projectLogo: 'assets/projects/GETKEY.png',
      projectTags: 'אפיון, ניהול פיתוח',
      projectPlatforms: 'Website, Dashboard',
      projectContent: 'אתר להשכרת/ רכישת נכסים מפרטיים, עסקיים ויזמים ללא דמי תיווך',
      projectGallery: [
        'https://drive.google.com/file/d/1jlpRijq73uCuzuoEMGleY804-iqHKpsR/view?usp=sharing',
        'https://drive.google.com/file/d/1HZmFOqE8Yj_aOG4h8ajvbBi36kXjDktS/view?usp=sharing'
      ]
    },
    {
      projectName: 'PopToApp‎',
      projectAddress: 'https://poptoapp.com/home',
      projectLogo: 'assets/projects/PopToApp.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח, פיתוח',
      projectPlatforms: 'Website',
      projectContent: 'אתר המאפשר לבצע שליחת הודעות ווטסאפ לאנשים שאינם באנשי הקשר',
      projectGallery: [
        'https://drive.google.com/file/d/1jlpRijq73uCuzuoEMGleY804-iqHKpsR/view?usp=sharing',
        'https://drive.google.com/file/d/1HZmFOqE8Yj_aOG4h8ajvbBi36kXjDktS/view?usp=sharing'
      ]
    },
    {
      projectName: 'DeepDiveDetailing',
      projectAddress: 'https://deepdivedetailing.com/',
      projectLogo: 'assets/projects/deepdivedetailing.png',
      projectTags: 'אפיון, ניהול פיתוח',
      projectPlatforms: 'Website, Dashboard',
      projectContent: 'אתר לתזמון וקביעת שירות נקיון לסירות ויאכטות, האתר פעיל בארצות הברית LV',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    },
    {
      projectName: 'EvenTime',
      projectAddress: 'https://eventime.co.il/',
      projectLogo: 'assets/projects/EvenTime.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח',
      projectPlatforms: 'Website',
      projectContent: 'אתר לתכנון וניהול של אירועים מול הספקים השונים, האתר מאפשר גישה לספקים וגם למחפשים',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    },
    {
      projectName: 'גינת אגוז',
      projectAddress: 'https://ginategoz.co.il/',
      projectLogo: 'assets/projects/GINAT_EGOZ.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח, פיתוח',
      projectPlatforms: 'Website',
      projectContent: 'אתר לאיתור מילים בתורה - ראשי תיבות, גימטריה ופסוקים',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    },
    {
      projectName: 'instaport',
      projectAddress: 'https://instaport.app/projects',
      projectLogo: 'assets/projects/instaport.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח',
      projectPlatforms: 'Website',
      projectContent: 'מערכת להפקת דוחות פרוייקטים המבוססים על פעילות MSP, ממשק חכם ואוטומטי לשיקוף המצב',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    },
    {
      projectName: 'livi',
      projectAddress: 'https://livi.co.il/auth/login',
      projectLogo: 'assets/projects/Livi.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח',
      projectPlatforms: 'Website',
      projectContent: 'מערכת לניהול דיירים בפרוייקטי תמ"א ופינוי בינוי, המערכת מיועדת למינהלת, לבעלי המקצוע ולדיירים',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    }
  ];

    // apps projects
    public appsProjectsArray: Array<any> = [
      {
        projectName: 'שיפוצים פלוס‎',
        projectAddress: 'https://play.google.com/store/apps/details?id=com.johnny.johnnyapp&gl=IL',
        projectLogo: 'assets/projects/SHIPLUS.png',
        projectTags: 'אפיון, ניהול פיתוח',
        projectPlatforms: 'Mobile app, Website, Dashboard',
        projectContent: 'אפליקציה לניהול לקוחות לבעלי מקצוע - התראות על שיחות, עדכון פרטים ויצירת תזכורות',
        projectGallery: [
          'https://drive.google.com/file/d/1jlpRijq73uCuzuoEMGleY804-iqHKpsR/view?usp=sharing',
          'https://drive.google.com/file/d/1HZmFOqE8Yj_aOG4h8ajvbBi36kXjDktS/view?usp=sharing'
        ]
      },
      {
        projectName: 'iGet',
        projectAddress: 'https://play.google.com/store/apps/details?id=com.levelapp.iget',
        projectLogo: 'assets/projects/iGet.png',
        projectTags: 'אפיון, ניהול פיתוח',
        projectPlatforms: 'Mobile app, Dashboard',
        projectContent: 'אפליקציה למשלוחים- כל אחד יכול להרשם כנהג או כעסק ולקבל משלוחים או למסור משלוחים',
        projectGallery: [
          'https://drive.google.com/file/d/1jlpRijq73uCuzuoEMGleY804-iqHKpsR/view?usp=sharing',
          'https://drive.google.com/file/d/1HZmFOqE8Yj_aOG4h8ajvbBi36kXjDktS/view?usp=sharing'
        ]
      },
      {
        projectName: 'bReal',
        projectAddress: 'https://play.google.com/store/apps/details?id=com.anyapp.breal',
        projectLogo: 'assets/projects/bReal.png',
        projectTags: 'אפיון, עיצוב, ניהול פיתוח',
        projectPlatforms: 'Mobile app, Dashboard',
        projectContent: 'אפליקציה למכירת / השכרת דירות באמצעות מתווך. האפליקציה מאפשרת לפרסם נכס ולנהל את הפגישות',
        projectGallery: [
          'https://drive.google.com/file/d/1jlpRijq73uCuzuoEMGleY804-iqHKpsR/view?usp=sharing',
          'https://drive.google.com/file/d/1HZmFOqE8Yj_aOG4h8ajvbBi36kXjDktS/view?usp=sharing'
        ]
      },
      {
        projectName: 'תוביל-לי',
        projectAddress: 'https://play.google.com/store/apps/details?id=com.truckos&gl=IL',
        projectLogo: 'assets/projects/tovil_li.png',
        projectTags: 'אפיון, עיצוב, ניהול פיתוח',
        projectPlatforms: 'Mobile app, Dashboard',
        projectContent: 'אפליקציה לאיתור נהגי משאיות בסביבה והזמנת שירותי הובלה ושינוע - אפליקציה לנהגים ולמחפשים',
        projectGallery: [
          'https://drive.google.com/file/d/1IyzIYmBFmVF-SiTb5W1BsCtnr0Q6MX_Q/view?usp=sharing',
          'https://drive.google.com/file/d/1r9uBtlALuQ7OTGPeoZZcKKnGsMOa6PH4/view?usp=sharing',
          'https://drive.google.com/file/d/1X6_ka6z9mcPrCNE-Vo8MWI45SCzKW3IQ/view?usp=sharing'
        ]
      },
      {
        projectName: 'Bounce',
        projectAddress: '#',
        projectLogo: 'assets/projects/bounce.png',
        projectTags: 'אפיון, עיצוב, ניהול פיתוח',
        projectPlatforms: 'Mobile app, Dashboard',
        projectContent: 'אפליקציה ליצירת וניהול מסיבות פרטיות - האפליקציה פועלת בארצות הברית',
        projectGallery: [
          'https://drive.google.com/file/d/1GZ1Xlqiz9UxPIvKO-uxmHHl0DaMXzZuD/view?usp=sharing',
          'https://drive.google.com/file/d/1vvJYFShC8Dmew5Mm8KnVetmAZGsbB1js/view?usp=sharing',
          'https://drive.google.com/file/d/1CWPPIU_Vea0meH6qn3VH0xHfyMyfd6Si/view?usp=sharing'
        ]
      },
      {
        projectName: 'יומן הדברה ירוק',
        projectAddress: 'https://play.google.com/store/apps/details?id=com.anyapp.pest&gl=IL',
        projectLogo: 'assets/projects/pest_control.png',
        projectTags: 'אפיון, עיצוב, ניהול פיתוח',
        projectPlatforms: 'Mobile app, Dashboard',
        projectContent: 'אפליקציה למדבירי מזיקים ליצירת יומני הדברה אלקטרוניים',
        projectGallery: [
          'https://drive.google.com/file/d/1r5UY7z9Zz0GXomKf1ddMzyuqH4cQ188B/view?usp=sharing',
          'https://drive.google.com/file/d/1eV0rTfrYnU26QwAIEbf63cfHo58WbKi1/view?usp=sharing'
        ]
      },
      {
        projectName: 'Drive Please',
        projectAddress: '#',
        projectLogo: 'assets/projects/drive_please.png',
        projectTags: 'אפיון, עיצוב, פיתוח',
        projectPlatforms: 'Mobile app',
        projectContent: 'אפליקציה ללימוד תיאוריה, מבוסס על השאלות במשרד התחבורה (האפליקציה כבר לא בחנות)',
        projectGallery: [
          'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
          'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
          'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
        ]
      }
    ];


  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void { }

}