import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviewes-section',
  templateUrl: './reviewes-section.component.html',
  styleUrls: ['./reviewes-section.component.scss']
})
export class ReviewesSectionComponent implements OnInit {


  review?: {
    reviewerImg: string,
    reviewerName: string,
    reviewerContent: string,
    reviewID?: number

  };

  // Websites reviews
  public reviewsArray: Array<any> = [
    {
      reviewerImg: 'https://lh3.googleusercontent.com/XNSx-DhYiFAZUGmpDQYAjlWQ2SbYpCNUCbd6tv20LNbv-HLMDHjh-6oCxt-1HO0Db7e6VlaoaPBn1dTPftK0zuTRPTe2JYRotjzcIv4A3jCLJhjBoMCwtolmbEqMEWrYjCCokWXRGn3Ut9pFjeIACHYZiooDzae8thF84t-gH1emF5lluUZh3kv4qAd25e6q6PxysK5Lxg1uLRwSiz_neTcketAM3MlX_xfh-BZZIDVio4u8Aqm8bYH2AdwWiCMcPWpmXvF2HDM8gFDvZtWadElrzB2xPGRl72OVtKKK8WnOoXnVo4FbTcNP38JdDj9kVksMGbM9e5bHEE7yrFpGMSnFBaBpe0lXU3Vi4v6yRHv1WkGn67p7es7pIA2cNbAs7gpH0g7b1YiQw1bgfcdJRUs5_VGAkDnOdSlabSKhKs4qvseRL-iMCPPnnpJOrDaNy0mzp4EQ2GwFk2y1f29lFFbJCTxE3c0Utu2goa8pm5b2UvGCx09ufndJsiM8XEjRjJnwsp25ck5dVUbicQXoD4aU9nIZz7I1bwejQBzDLkP6q03Jthzg391DFUYmzYZfNSm2lSjB4vamaD7g0ceEvj60xebYCNGoqKeAIFXG5PJMb4LwicByb2LQ2CobswBl6ov6iIJQLSS6-AsnGfTEKKwwW9di4zqqT5v-xey5WMekcRKwFS6j-rOTk-gaqBjnTCl49mV3AjPFOGJau9kqCPGU=w1605-h903-no?authuser=0',
      reviewerName: 'אייל חדד',
      reviewerContent: 'גכגכג גכ גכג כזוהי הביקורת של המבקר הוא ממש מחבב אותי ואני שמח מזה כי זה נותן לי נפח לתיק עבודות שלי וזה פשוט נהדר',
    },
    {
      reviewerImg: 'https://lh3.googleusercontent.com/XNSx-DhYiFAZUGmpDQYAjlWQ2SbYpCNUCbd6tv20LNbv-HLMDHjh-6oCxt-1HO0Db7e6VlaoaPBn1dTPftK0zuTRPTe2JYRotjzcIv4A3jCLJhjBoMCwtolmbEqMEWrYjCCokWXRGn3Ut9pFjeIACHYZiooDzae8thF84t-gH1emF5lluUZh3kv4qAd25e6q6PxysK5Lxg1uLRwSiz_neTcketAM3MlX_xfh-BZZIDVio4u8Aqm8bYH2AdwWiCMcPWpmXvF2HDM8gFDvZtWadElrzB2xPGRl72OVtKKK8WnOoXnVo4FbTcNP38JdDj9kVksMGbM9e5bHEE7yrFpGMSnFBaBpe0lXU3Vi4v6yRHv1WkGn67p7es7pIA2cNbAs7gpH0g7b1YiQw1bgfcdJRUs5_VGAkDnOdSlabSKhKs4qvseRL-iMCPPnnpJOrDaNy0mzp4EQ2GwFk2y1f29lFFbJCTxE3c0Utu2goa8pm5b2UvGCx09ufndJsiM8XEjRjJnwsp25ck5dVUbicQXoD4aU9nIZz7I1bwejQBzDLkP6q03Jthzg391DFUYmzYZfNSm2lSjB4vamaD7g0ceEvj60xebYCNGoqKeAIFXG5PJMb4LwicByb2LQ2CobswBl6ov6iIJQLSS6-AsnGfTEKKwwW9di4zqqT5v-xey5WMekcRKwFS6j-rOTk-gaqBjnTCl49mV3AjPFOGJau9kqCPGU=w1605-h903-no?authuser=0',
      reviewerName: 'דוד סבתו‎',
      reviewerContent: 'גכגכג גכ גכג כזוהי הביקורת של המבקר הוא ממש מחבב אותי ואני שמח מזה כי זה נותן לי נפח לתיק עבודות שלי וזה פשוט נהדר',
    },
    {
      reviewerImg: 'https://lh3.googleusercontent.com/XNSx-DhYiFAZUGmpDQYAjlWQ2SbYpCNUCbd6tv20LNbv-HLMDHjh-6oCxt-1HO0Db7e6VlaoaPBn1dTPftK0zuTRPTe2JYRotjzcIv4A3jCLJhjBoMCwtolmbEqMEWrYjCCokWXRGn3Ut9pFjeIACHYZiooDzae8thF84t-gH1emF5lluUZh3kv4qAd25e6q6PxysK5Lxg1uLRwSiz_neTcketAM3MlX_xfh-BZZIDVio4u8Aqm8bYH2AdwWiCMcPWpmXvF2HDM8gFDvZtWadElrzB2xPGRl72OVtKKK8WnOoXnVo4FbTcNP38JdDj9kVksMGbM9e5bHEE7yrFpGMSnFBaBpe0lXU3Vi4v6yRHv1WkGn67p7es7pIA2cNbAs7gpH0g7b1YiQw1bgfcdJRUs5_VGAkDnOdSlabSKhKs4qvseRL-iMCPPnnpJOrDaNy0mzp4EQ2GwFk2y1f29lFFbJCTxE3c0Utu2goa8pm5b2UvGCx09ufndJsiM8XEjRjJnwsp25ck5dVUbicQXoD4aU9nIZz7I1bwejQBzDLkP6q03Jthzg391DFUYmzYZfNSm2lSjB4vamaD7g0ceEvj60xebYCNGoqKeAIFXG5PJMb4LwicByb2LQ2CobswBl6ov6iIJQLSS6-AsnGfTEKKwwW9di4zqqT5v-xey5WMekcRKwFS6j-rOTk-gaqBjnTCl49mV3AjPFOGJau9kqCPGU=w1605-h903-no?authuser=0',
      reviewerName: 'אוהד סהר‎',
      reviewerContent: 'גכגכג גכ גכג כזוהי הביקורת של המבקר הוא ממש מחבב אותי ואני שמח מזה כי זה נותן לי נפח לתיק עבודות שלי וזה פשוט נהדר',
    },
    {
      reviewerImg: 'https://lh3.googleusercontent.com/XNSx-DhYiFAZUGmpDQYAjlWQ2SbYpCNUCbd6tv20LNbv-HLMDHjh-6oCxt-1HO0Db7e6VlaoaPBn1dTPftK0zuTRPTe2JYRotjzcIv4A3jCLJhjBoMCwtolmbEqMEWrYjCCokWXRGn3Ut9pFjeIACHYZiooDzae8thF84t-gH1emF5lluUZh3kv4qAd25e6q6PxysK5Lxg1uLRwSiz_neTcketAM3MlX_xfh-BZZIDVio4u8Aqm8bYH2AdwWiCMcPWpmXvF2HDM8gFDvZtWadElrzB2xPGRl72OVtKKK8WnOoXnVo4FbTcNP38JdDj9kVksMGbM9e5bHEE7yrFpGMSnFBaBpe0lXU3Vi4v6yRHv1WkGn67p7es7pIA2cNbAs7gpH0g7b1YiQw1bgfcdJRUs5_VGAkDnOdSlabSKhKs4qvseRL-iMCPPnnpJOrDaNy0mzp4EQ2GwFk2y1f29lFFbJCTxE3c0Utu2goa8pm5b2UvGCx09ufndJsiM8XEjRjJnwsp25ck5dVUbicQXoD4aU9nIZz7I1bwejQBzDLkP6q03Jthzg391DFUYmzYZfNSm2lSjB4vamaD7g0ceEvj60xebYCNGoqKeAIFXG5PJMb4LwicByb2LQ2CobswBl6ov6iIJQLSS6-AsnGfTEKKwwW9di4zqqT5v-xey5WMekcRKwFS6j-rOTk-gaqBjnTCl49mV3AjPFOGJau9kqCPGU=w1605-h903-no?authuser=0',
      reviewerName: 'בר גולדפילד‎',
      reviewerContent: 'גכגכג גכ גכג כזוהי הביקורת של המבקר הוא ממש מחבב אותי ואני שמח מזה כי זה נותן לי נפח לתיק עבודות שלי וזה פשוט נהדר',
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
