import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-project-page',
  templateUrl: './app-project-page.component.html',
  styleUrls: ['./app-project-page.component.scss']
})
export class AppProjectPageComponent implements OnInit {

  appProject: any;

  // apps projects
  public appsProjectsArray: Array<any> = [
    {
      projectName: 'שיפוצים פלוס‎',
      projectAddress: 'https://play.google.com/store/apps/details?id=com.johnny.johnnyapp&gl=IL',
      projectLogo: 'assets/projects/SHIPLUS.png',
      projectTags: 'אפיון, ניהול פיתוח',
      projectPlatforms: 'Mobile app, Website, Dashboard',
      projectContent: 'אפליקציה לניהול לקוחות לבעלי מקצוע - התראות על שיחות, עדכון פרטים ויצירת תזכורות',
      projectGallery: [
        'https://drive.google.com/file/d/1jlpRijq73uCuzuoEMGleY804-iqHKpsR/view?usp=sharing',
        'https://drive.google.com/file/d/1HZmFOqE8Yj_aOG4h8ajvbBi36kXjDktS/view?usp=sharing'
      ]
    },
    {
      projectName: 'iGet',
      projectAddress: 'https://play.google.com/store/apps/details?id=com.levelapp.iget',
      projectLogo: 'assets/projects/iGet.png',
      projectTags: 'אפיון, ניהול פיתוח',
      projectPlatforms: 'Mobile app, Dashboard',
      projectContent: 'אפליקציה למשלוחים- כל אחד יכול להרשם כנהג או כעסק ולקבל משלוחים או למסור משלוחים',
      projectGallery: [
        'https://drive.google.com/file/d/1jlpRijq73uCuzuoEMGleY804-iqHKpsR/view?usp=sharing',
        'https://drive.google.com/file/d/1HZmFOqE8Yj_aOG4h8ajvbBi36kXjDktS/view?usp=sharing'
      ]
    },
    {
      projectName: 'bReal',
      projectAddress: 'https://play.google.com/store/apps/details?id=com.anyapp.breal',
      projectLogo: 'assets/projects/bReal.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח',
      projectPlatforms: 'Mobile app, Dashboard',
      projectContent: 'אפליקציה למכירת / השכרת דירות באמצעות מתווך. האפליקציה מאפשרת לפרסם נכס ולנהל את הפגישות שנקבעות',
      projectGallery: [
        'https://drive.google.com/file/d/1jlpRijq73uCuzuoEMGleY804-iqHKpsR/view?usp=sharing',
        'https://drive.google.com/file/d/1HZmFOqE8Yj_aOG4h8ajvbBi36kXjDktS/view?usp=sharing'
      ]
    },
    {
      projectName: 'תוביל-לי',
      projectAddress: 'https://play.google.com/store/apps/details?id=com.truckos&gl=IL',
      projectLogo: 'assets/projects/tovil_li.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח',
      projectPlatforms: 'Mobile app, Dashboard',
      projectContent: 'אפליקציה לאיתור נהגי משאיות בסביבה והזמנת שירותי הובלה ושינוע - אפליקציה לנהגים ולמחפשים',
      projectGallery: [
        'https://drive.google.com/file/d/1IyzIYmBFmVF-SiTb5W1BsCtnr0Q6MX_Q/view?usp=sharing',
        'https://drive.google.com/file/d/1r9uBtlALuQ7OTGPeoZZcKKnGsMOa6PH4/view?usp=sharing',
        'https://drive.google.com/file/d/1X6_ka6z9mcPrCNE-Vo8MWI45SCzKW3IQ/view?usp=sharing'
      ]
    },
    {
      projectName: 'Bounce',
      projectAddress: '#',
      projectLogo: 'assets/projects/bounce.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח',
      projectPlatforms: 'Mobile app, Dashboard',
      projectContent: 'אפליקציה ליצירת וניהול מסיבות פרטיות - האפליקציה פועלת בארצות הברית',
      projectGallery: [
        'https://drive.google.com/file/d/1GZ1Xlqiz9UxPIvKO-uxmHHl0DaMXzZuD/view?usp=sharing',
        'https://drive.google.com/file/d/1vvJYFShC8Dmew5Mm8KnVetmAZGsbB1js/view?usp=sharing',
        'https://drive.google.com/file/d/1CWPPIU_Vea0meH6qn3VH0xHfyMyfd6Si/view?usp=sharing'
      ]
    },
    {
      projectName: 'יומן הדברה ירוק',
      projectAddress: 'https://play.google.com/store/apps/details?id=com.anyapp.pest&gl=IL',
      projectLogo: 'assets/projects/pest_control.png',
      projectTags: 'אפיון, עיצוב, ניהול פיתוח',
      projectPlatforms: 'Mobile app, Dashboard',
      projectContent: 'אפליקציה למדבירי מזיקים ליצירת יומני הדברה אלקטרוניים',
      projectGallery: [
        'https://drive.google.com/file/d/1r5UY7z9Zz0GXomKf1ddMzyuqH4cQ188B/view?usp=sharing',
        'https://drive.google.com/file/d/1eV0rTfrYnU26QwAIEbf63cfHo58WbKi1/view?usp=sharing'
      ]
    },
    {
      projectName: 'Drive Please',
      projectAddress: '#',
      projectLogo: 'assets/projects/drive_please.png',
      projectTags: 'אפיון, עיצוב, פיתוח',
      projectPlatforms: 'Mobile app',
      projectContent: 'אפליקציה ללימוד תיאוריה, מבוסס על השאלות במשרד התחבורה (האפליקציה כבר לא בחנות)',
      projectGallery: [
        'https://lh3.googleusercontent.com/1dqHy0x-tJa_S7SRr_90wdef5IH2KJv9QZaUSGKQiusILKEQTA05b2trjnxD_927RlA',
        'https://lh3.googleusercontent.com/mY3dfe2lUlsSGb1PwmG81tt9rf-XEUw7G9NvkhJQkzSrNzvido5WaBLo5byT28Of5HU',
        'https://lh3.googleusercontent.com/TiLnq5R6tkB9_z3BYdWK8pBxyjTU5iEFi0LfJBCjQ3Jt4gIADdNJZIO2YpHK88vjP2c'
      ]
    }
  ];
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    // const id = this.route.snapshot.paramMap.get('id');
    // this.project = this.projectsArray[id];
    // console.log(id)
    // console.log(this.project)
    this.route.params.subscribe(paramsId => {
      console.log(paramsId.id)
      const id = paramsId.id;
      this.appProject = this.appsProjectsArray[id];
    });

    // this.route.params.subscribe(params => {
    //   this.project = this.project?[params.id];
    // });

  }


  navigateToAppPrevProject() {
    console.log(this.appProject.projectID)
    const currentApp = this.appProject.projectID;
    this.router.navigate([`projects/app/${this.appProject.projectID}`])
  }

}
