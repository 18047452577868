import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss']
})
export class ReviewCardComponent implements OnInit {

  @Input() reviewerImg?: string;
  @Input() reviewerName?: string;
  @Input() reviewerContent?: string;
  @Input() reviewID?: number;

  constructor() { }

  ngOnInit(): void {
  }

}
